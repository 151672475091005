import "./HomePage.scss";

import React from "react";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

export function HomePage() {
  return (
    <Layout>
      <Head
        title="URL Shortener, Custom Short URLs, Free Link Shortener | Short Route"
        keywords="url shortener, tiny url, link shortening, short url, shortening url, shorten url, shortener url, link shortener, short link, shorten links, shorter link, shortlink, free url shortener, short route, qr code generator, qr code"
        description="Short Route is a platform for URL shortening and link management. Easily generate custom short links, branded links, and QR codes with our free link shortener service."
      />

      <section className="section first-section">
        <div className="content">
          <span className="slug">Create Short URLs</span>
          <h1 className="main-text">
            We are shortening service that specializes in creating, monitoring,
            branding, and sharing short URLs.
          </h1>
        </div>
      </section>

      <section className="section input-section">
        <div className="content">
          <input
            className="input"
            placeholder="Paste long url and shorten it"
          />
          <button
            className="button"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_SPACE_URL}/sign-up`;
            }}
          >
            Create Short Link
          </button>
        </div>
      </section>

      <section className="section opportunities">
        <div className="content">
          <div className="item">
            <div className="item-title">
              <img src="/img/home-page-list-item-1.svg" alt="icon" />
              <h2>Shorten URLs</h2>
            </div>
            <span>
              The Short-Route.com URL Shortener transforms lengthy links into
              tidy, shareable ones! Customize your brand with your own Custom
              Domains and enjoy the trust of over 20 million monthly visitors.
              Plus, effortlessly generate trackable QR Codes.
            </span>
          </div>

          <div className="item">
            <div className="item-title">
              <img src="/img/home-page-list-item-2.svg" alt="icon" />
              <h2>Track Link Clicks</h2>
            </div>
            <span>
              Short-Route.com has shortened and tracked more than 40 million
              links, recording over 700 million link clicks. It provides
              valuable insights into user origins and serves as a click counter
              tool for comprehensive link analytics
            </span>
          </div>

          <div className="item">
            <div className="item-title">
              <img src="/img/home-page-list-item-3.svg" alt="icon" />
              <h2>QR Codes</h2>
            </div>
            <span>
              Short-Route.com enables you to effortlessly create and personalize
              QR codes that align with your brand while also monitoring their
              performance. Leverage QR codes to expand your business reach and
              evaluate their effectiveness in your marketing campaigns.
            </span>
          </div>
        </div>
      </section>

      <section className="section custom-domain">
        <div className="content">
          <span>add domain</span>
          <h2>Looking to ADD your own custom domain?</h2>
          <button
            className="button"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_SPACE_URL}/sign-up`;
            }}
          >
            Get Started
          </button>
        </div>
      </section>

      <section className="section platform">
        <div className="content">
          <span className="slug">Simplify</span>
          <h2 className="title">
            Short-Route.com More Than Just a URL Shortener
          </h2>
          <span className="desc">
            Short-Route.com is an advanced platform for URL shortening that
            continuously evolves, blending innovation with ease of use to
            provide a smooth experience in managing and shortening links.
          </span>
          <br />
          <div className="buttons">
            <button className="button white" onClick={() => {
              window.location.href = `${process.env.REACT_APP_SPACE_URL}/sign-up`;
            }}>Sign Up</button>
            <a className="log-in" href={process.env.REACT_APP_SPACE_URL}>
              Log In
            </a>
          </div>
        </div>
      </section>

      <section className="section empowerment">
        <div className="content">
          <div className="left">
            <span className="slug">Empowerment</span>
            <h2>
              Create your own branded short
              <br /> links with Short-Route.com
            </h2>
            <span className="desc">
              Enhance your link engagement and strengthen your brand presence.{" "}
              <br />
              Gain insights into your link's click-through rates with detailed
              click analytics. Discover who clicks your links, their devices,
              and their locations for comprehensive understanding.
            </span>
          </div>

          <img src="/img/home-page-img-1.svg" alt="home page" />
        </div>
      </section>
    </Layout>
  );
}
