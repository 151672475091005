import { Alert } from "../components/Alert";

import { HomePage } from "./HomePage";

export function Page404() {
  return (
    <>
      <Alert danger={true}>Page not found.</Alert>
      <HomePage />
    </>
  );
}