import "./QRPage.scss";

import React from "react";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

export function QRPage() {
  return (
    <Layout>
      <Head
        title="Free QR Code Generator"
        description="Create your own QR codes for free using our online QR code generator."
        keywords="url shortener, tiny url, link shortening, short url, shortening url, shorten url, shortener url, link shortener, short link, shorten links, shorter link, shortlink, free url shortener, short route, qr code generator, qr code"
      />
      <section className="section about-qr">
        <div className="content">
          <div className="left">
            <span className="slug">QR Evolution</span>
            <h1 className="title">What’s a QR code?</h1>
            <span className="desc">
              The term "QR code" stands for Quick Response Code, functioning
              similarly to a barcode and initially developed by Denso Wave in
              1994. QR codes contain data readable by a QR code scanner,
              typically a smartphone app. Scanning the code usually directs
              users to a predetermined URL chosen by the QR code creator.
              Additionally, QR codes find utility in inventory tracking, product
              identification, and document management.
            </span>
            <button
              className="button about-qr-button"
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_SPACE_URL}/sign-up`;
              }}
            >
              Get Started
            </button>
          </div>
          <img src="/img/qr-top-image.svg" alt="qr" />
        </div>
      </section>

      <div className="on-top">
        <section className="section qr-form">
          <div className="content">
            <div className="panel">
              <img src="/img/qr-form-img.svg" alt="qr" />

              <form className="form">
                <h2 className="form-title">Generate QR Code</h2>
                <input className="input" placeholder="Type your link here" />
                <div className="form-bottom">
                  <button
                    className="button"
                    onClick={() => {
                      window.location.href = `${process.env.REACT_APP_SPACE_URL}/sign-up`;
                    }}
                  >
                    Download QR Code
                  </button>
                  <span className="form-text">
                    More functionality in your client space
                  </span>
                </div>
              </form>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="content">
            <div className="panel panel-benefits">
              <span className="number">01</span>
              <div className="panel-content">
                <h3 className="title">
                  How can QR codes be effectively utilized for marketing
                  purposes?
                </h3>
                <p className="desc">
                  QR codes can be incorporated into your business's flyers,
                  business cards, car decals, billboards, and various other
                  marketing materials. They offer the advantage of accommodating
                  additional content in diverse formats without affecting your
                  packaging design, brand identity, or overwhelming your
                  audience with excessive text. This approach also helps in
                  cost-effective printing strategies.
                </p>
              </div>
            </div>

            <div className="panel panel-benefits">
              <span className="number">02</span>
              <div className="panel-content">
                <h3 className="title">
                  What are the benefits of using QR Codes?
                </h3>
                <p className="desc">
                  QR codes offer a wide range of advantages for businesses,
                  individuals, and organizations. They enable swift and
                  effortless access to information through a simple scan on
                  mobile devices, providing access to contact details, product
                  descriptions, web links, and payment options. QR codes are a
                  convenient and efficient method to store data without
                  consuming excessive space or necessitating complex hardware.
                  Moreover, they excel compared to traditional barcodes by
                  storing larger data amounts securely, making them perfect for
                  tracking products or services, promoting events or products,
                  and facilitating secure access to services or websites.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
