import { Link } from "react-router-dom";
import "./Navigation.scss";

import React, { useState } from "react";

function Nav({
  text,
  submenu,
  active,
  onActive,
  onHide,
}: {
  text: string;
  active: boolean;
  onActive: () => void;
  onHide: () => void;
  submenu: { text: string; link: string }[];
}) {
  return (
    <li
      className="nav-list-item"
      onClick={(e) => {
        // @ts-ignore
        if (e.target.classList.contains("nav-list-item-text")) {
          onActive();
        }
      }}
    >
      <span className="nav-list-item-text">{text}</span>

      <ul className={"nav-submenu" + (active ? " open" : "")}>
        {submenu.map(({ text, link }, index) => {
          return (
            <li key={index} className="nav-submenu-item">
              <div className="nav-submenu-item-content">
                <Link onClick={() => onHide()} to={link}>
                  {text}
                </Link>
                <img src="/img/navbar-submenu-item-arrow.svg" alt="arrow" />
              </div>
              {index !== submenu.length - 1 && (
                <div className="nav-submenu-separator">
                  <div />
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </li>
  );
}

function Lang() {
  return <div className="nav-lang">EN</div>;
}

export function Naivgation() {
  const [active, setActive] = useState(-1);
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <header className="section navbar-section">
      <div className="content">
        <nav className="top-navigation">
          <button
            className="mobile-menu-toggle"
            onClick={() => setMenuOpen(!isMenuOpen)}
          >
            <img
              width={24}
              height={24}
              src="/img/menu-icon.svg"
              alt="menu icon"
            />
          </button>

          {isMenuOpen && (
            <nav className="mobile-menu">
              <div className="align-left">
                <button
                  className="mobile-menu-toggle"
                  onClick={() => setMenuOpen(!isMenuOpen)}
                >
                  <img
                    width={24}
                    height={24}
                    src="/img/menu-icon.svg"
                    alt="menu icon"
                  />
                </button>
              </div>

              <div className="mobile-menu-content">
                <ul>
                  <li className="title">Products</li>
                  <li>
                    <Link
                      to="/url-shortener"
                      onClick={() => setMenuOpen(false)}
                    >
                      URL Shortener
                    </Link>
                  </li>
                  <li>
                    <Link to="/qr" onClick={() => setMenuOpen(false)}>
                      QR Generation
                    </Link>
                  </li>
                  <li className="title">Resources</li>
                  <li>
                    <Link to="/" onClick={() => setMenuOpen(false)}>
                      API Documentation
                    </Link>
                  </li>
                  <li>
                    <Link to="/questions" onClick={() => setMenuOpen(false)}>
                      Frequently Asked Questions
                    </Link>
                  </li>
                  <li className="title">Authentication</li>
                  <li>
                    <a
                      href={process.env.REACT_APP_SPACE_URL}
                      onClick={() => setMenuOpen(false)}
                    >
                      Log In
                    </a>
                  </li>
                  <li>
                    <a
                      href={process.env.REACT_APP_SPACE_URL + "/sign-up"}
                      onClick={() => setMenuOpen(false)}
                    >
                      Sign up
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          )}

          <ul className="nav-list">
            <Nav
              text="Products"
              active={active === 0}
              onHide={() => setActive(-1)}
              onActive={() => {
                if (active !== 0) setActive(0);
                else setActive(-1);
              }}
              submenu={[
                { text: "URL Shortener", link: "/url-shortener" },
                { text: "QR Generation", link: "/qr" },
              ]}
            />
            <Nav
              text="Resources"
              active={active === 1}
              onHide={() => setActive(-1)}
              onActive={() => {
                if (active !== 1) setActive(1);
                else setActive(-1);
              }}
              submenu={[
                { text: "API Documentation", link: "/" },
                { text: "Frequently Asked Questions", link: "/questions" },
              ]}
            />
          </ul>

          <Link to="/" className="brand">
            Short Route
          </Link>

          <div className="nav-right">
            <Lang />
            <div className="nav-right-separator">
              <div />
            </div>

            <a className="nav-log-in" href={process.env.REACT_APP_SPACE_URL}>
              Log In
            </a>
            <a
              className="nav-sign-up button white"
              href={`${process.env.REACT_APP_SPACE_URL}/sign-up`}
            >
              Sign up
            </a>
          </div>
        </nav>
      </div>
    </header>
  );
}
