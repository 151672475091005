import { useEffect } from "react";

export function Head({
  title,
  keywords,
  description,
}: {
  title: string;
  keywords?: string;
  description?: string;
}) {
  useEffect(() => {
    const titleElement = document.querySelector("title");
    if (titleElement) titleElement.innerText = title;

    const ogTitleElement = document.querySelector("meta[property='og:title']");
    if (ogTitleElement) ogTitleElement.setAttribute("content", title);

    const keywordsElement = document.querySelector("meta[name=keywords]");
    if (keywordsElement) keywordsElement.setAttribute("content", keywords || "");

    const descriptionElement = document.querySelector("meta[name=description]");
    if (descriptionElement) descriptionElement.setAttribute("content", description || "");

    const ogDescriptionElement = document.querySelector("meta[property='og:description']");
    if (ogDescriptionElement) ogDescriptionElement.setAttribute("content", description || "");
  }, [title, keywords, description]);

  return null;
}
