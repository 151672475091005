import "./Footer.scss";

import React from "react";
import { Link } from "react-router-dom";

export function Footer() {
  function scrollTop() {
    window.scroll(0, 0);
  }

  return (
    <footer className="footer">
      <div className="content">
        <nav className="navigation">
          <div className="nav-block">
            <h3 className="title">Products</h3>

            <Link to="/url-shortener" onClick={() => scrollTop()}>URL Shortener</Link>
            <Link to="/qr" onClick={() => scrollTop()}>
              QR Generation
            </Link>
          </div>

          <div className="nav-block">
            <h3 className="title">Resources</h3>

            <a href="/">Api Documentation</a>
            <Link to="/questions" onClick={() => scrollTop()}>
              Frequently Asked Questions
            </Link>
          </div>

          <div className="nav-block">
            <h3 className="title">Short-Route</h3>

            <a href="mailto:info@short-route.com">Contact</a>
            <Link to="/terms-of-use" onClick={() => scrollTop()}>Terms of use</Link>
            <Link to="/privacy-policy" onClick={() => scrollTop()}>Privacy Policy</Link>
            <Link to="/cookie-policy" onClick={() => scrollTop()}>Cookies</Link>
          </div>
          <div className="nav-block last">
            <h3 className="title">Short Route</h3>

            <div className="social">
              <a href="/">
                <img src="/img/facebook-icon.svg" alt="facebook" />
              </a>
              <a href="/">
                <img src="/img/instagram-icon.svg" alt="instagram" />
              </a>
              <a href="/">
                <img src="/img/twitter-icon.svg" alt="twitter"/>
              </a>
              <a href="/">
                <img src="/img/linkedin-icon.svg" alt="linkedin"/>
              </a>
            </div>

            <a className="mailto" href="mailto:info@short-route.com">
              info@short-route.com
            </a>
          </div>
        </nav>
      </div>
      <div className="sign">@ 2024 Short-Route.com | All Rights Reserved ®</div>
    </footer>
  );
}
