import "./URLShotenerPage.scss";

import React from "react";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

export function URLShotenerPage() {
  return (
    <Layout>
      <Head
        title="URL Shortener, Custom Short URLs, Free Link Shortener | Short Route"
        keywords="url shortener, tiny url, link shortening, short url, shortening url, shorten url, shortener url, link shortener, short link, shorten links, shorter link, shortlink, free url shortener, short route, qr code generator, qr code"
        description="Short Route is a platform for URL shortening and link management. Easily generate custom short links, branded links, and QR codes with our free link shortener service."
      />

      <section className="section url-shotener-section-1">
        <div className="content">
          <span className="slug">URL Shortener</span>
          <h1 className="title">
            Short-Route.com is the best short link management service to track,
            brand, and share short URLs.
          </h1>

          <button
            className="button white"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_SPACE_URL}/sign-up`;
            }}
          >
            Get Started
          </button>
        </div>
      </section>

      <section className="section url-shotener-section">
        <div className="content reverse">
          <div className="url-shortener-block">
            <span className="slug">Brand Domains</span>
            <h2 className="title">Custom Domains</h2>
            <p className="description">
              Transform your links into potent marketing assets. Utilize custom
              domains to replace "short-link.com" with your own domain name,
              ensuring your links are consistently recognizable across various
              channels.
            </p>
            <button
              className="button white"
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_SPACE_URL}/sign-up`;
              }}
            >
              Add Your Own Domain
            </button>
          </div>
        </div>
      </section>

      <section className="section url-shotener-section last">
        <div className="content">
          <div className="url-shortener-block">
            <span className="slug">LinkBoost</span>
            <h2 className="title">Create your own branded short links</h2>
            <p className="description">
              Boost click rates and brand visibility with your links. Dive deep
              into click analytics to understand your link's performance.
              Identify click demographics such as devices used and locations to
              refine your marketing strategy.
            </p>
            <button
              className="button white"
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_SPACE_URL}/sign-up`;
              }}
            >
              Start Your Way With Us
            </button>
          </div>
        </div>
      </section>
    </Layout>
  );
}
