import React from "react";
import { Naivgation } from "./Navigation";
import { Footer } from "./Footer";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Naivgation />
      <main>{children}</main>
      <Footer />
    </>
  );
}
