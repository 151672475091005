import "./QuestionsPage.scss";

import React, { useState } from "react";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

function Question({
  question,
  answer,
  number,
  open,
}: {
  number: string;
  question: string;
  answer: string;
  open?: boolean;
}) {
  const [isActive, setActive] = useState(open || false);

  return (
    <div
      className={"question" + (isActive ? " active" : "")}
      onClick={() => setActive(!isActive)}
    >
      <div className="question-button" role="button">
        {!isActive && <img src="/img/add-icon.svg" alt="icon" />}
        {isActive && <img src="/img/close-icon.svg" alt="icon" />}
      </div>

      <div className="question-content">
        <h2 className="question-title">{question}</h2>
        {isActive && <p className="question-answer">{answer}</p>}
      </div>
      <span className="question-number">{number}</span>
    </div>
  );
}

export function QuestionsPage() {
  return (
    <Layout>
      <Head
        title="Frequently Asked Questions about Short-Route.com URL Shortener"
        description="Find the answer to our most frequently asked questions"
        keywords="url shortener, tiny url, link shortening, short url, shortening url, shorten url, shortener url, link shortener, short link, shorten links, shorter link, shortlink, free url shortener, short route, qr code generator, qr code"
      />

      <section className="section">
        <div className="content">
          <h1 className="questions-title">Frequently Asked Questions</h1>

          <div className="questions">
            <Question
              open={true}
              number="01"
              question="What is a short link?"
              answer="A shortened link is a compact rendition of a lengthy web address (URL) crafted through a URL shortening service. These concise URLs are intended to be visually attractive, effortlessly shareable, and ideal for platforms with character constraints, like social media, text messages, and emails. They prove particularly useful for sharing content in scenarios with limited space or when aiming for a neat and streamlined presentation."
            />

            <Question
              number="02"
              question="Is Short Route free URL shortener?"
              answer="Short Route is a no-cost URL shortening service enabling users to generate concise, personalized, and traceable URLs."
            />

            <Question
              number="03"
              question="Do short links created with Short Route expire?"
              answer="Short links generated using Short-Route do not have an expiration date."
            />

            <Question
              number="04"
              question="Can I use my own custom or branded domains with Short-Route for URL shortening?"
              answer="Of course! Short Route is a flexible URL shortening tool that empowers you to integrate your personalized or branded domains for creating shortened URLs. This functionality ensures a consistent brand identity while utilizing short links."
            />
          </div>
        </div>
      </section>
    </Layout>
  );
}
